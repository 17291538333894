const Info = () => {
    return (
        <div className="container">
            <div className="instruct">
                <h2>Инструкция по активации карты</h2>
                <dl>
                    <dd>
                        <ol>
                            <li><span
                                className="emoji">📱</span><span>Войдите в App Store на вашем устройстве Apple.</span>
                            </li>
                            <li><span className="emoji">🔑</span><span>Перейдите в раздел вашего Apple ID.</span></li>
                            <li><span
                                className="emoji">💳</span><span>Выберите пункт «Погасить подарочную карту или код».</span>
                            </li>
                            <li><span className="emoji">📝</span><span>Нажмите «Ввести код вручную».</span></li>
                            <li><span className="emoji">💰</span><span>Введите код вашей карты, подтвердите, и средства мгновенно поступят на ваш счет.</span>
                            </li>
                        </ol>
                    </dd>
                </dl>
            </div>

            <div className="faq">

                <h2>Ответы на часто задаваемые вопросы</h2>
                <details>
                    <summary>Как быстро я смогу воспользоваться картой?</summary>
                    <div className="response">
                        Подарочная карта, которая представляет собой 16-значный код, становится доступной сразу же
                        после
                        покупки.
                    </div>
                </details>
                <details>
                    <summary>Что делать, если с картой будет что-то не так?</summary>
                    <div>
                        Мы ценим ваше доверие и нашу репутацию, готовы помочь в случае любых неожиданных ситуаций.
                        Достаточно обратиться в нашу техподдержку, и мы решим любой вопрос.
                    </div>
                </details>
            </div>

            <div className="reviews">
                <div className="reviews">
                    <h2>Отзывы</h2>
                    <div style={{width: '100%', height: '900px', overflow: 'hidden', position: 'relative'}}>
                        <iframe
                            style={{
                                width: '100%',
                                height: '100%',
                                border: '1px solid #e6e6e6',
                                borderRadius: '8px',
                                boxSizing: 'border-box'
                            }}
                            title="Отзывы"
                            src="https://yandex.ru/maps-reviews-widget/128970334378?comments"
                        ></iframe>

                        <a
                            style={{
                                boxSizing: 'border-box',
                                textDecoration: 'none',
                                color: '#b3b3b3',
                                fontSize: '10px',
                                fontFamily: 'YS Text, sans-serif',
                                padding: '0 16px',
                                position: 'absolute',
                                bottom: '8px',
                                width: '100%',
                                textAlign: 'center',
                                left: '0',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'block',
                                maxHeight: '14px',
                                whiteSpace: 'nowrap'
                            }}
                            href="https://yandex.ru/maps/org/kartaitunes/128970334378/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            KartaItunes на карте Красноярского края — Яндекс Карты
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Info;
