import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {websiteUrl} from '../pages/Homepage/Homepage';
import {addProductToCart, removeProductFromCart} from '../redux/reducers/user';

const Catalog = () => {
    const cart = useSelector((state) => state.user.cart);
    const [products, setProducts] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const dispatch = useDispatch();

    const [activeCategory, setActiveCategory] = useState('Карты для России');
    const categories = ['Карты для России', 'Карты для США']; // Массив категорий

    const handleCategoryClick = (category) => {
        setActiveCategory(category);
    };

    useEffect(() => {
        if (activeCategory === 'Карты для России') {
            setFiltered(products.filter((item) => item.country === 'ru'));
        } else {
            setFiltered(products.filter((item) => item.country === 'usa'));
        }
    }, [activeCategory, products]);

    useEffect(() => {
        axios.get(`${websiteUrl}/get-products`).then((res) => {
            setProducts(res.data);
            setFiltered(res.data.filter((item) => item.country === 'ru'));
        }).catch(error => {
            console.error('Ошибка при запросе продуктов:', error);
        });
    }, []);

    const add = (product) => {
        dispatch(addProductToCart(product));
    };

    const remove = (product) => {
        dispatch(removeProductFromCart(product));
    };

    return (
        <>
            <div className="categories">
                {categories.map((categoryItem) => (
                    <div
                        key={categoryItem}
                        onClick={() => handleCategoryClick(categoryItem)}
                        className={activeCategory === categoryItem ? 'categories-type categories-type-active' : 'categories-type'}
                    >
                        {categoryItem}
                    </div>
                ))}
            </div>
            <div className="catalog">
                {filtered.map((item) => {
                    const increasedPrice = item.price * 1.05;
                    return (
                        <div key={item.id} className="catalog-card">
                            <div className={`main-card ${item.isPremium && 'premium'}`}>
                                <img src={item.photoSrc} alt={`Card value: ${item.value}`} className="card-image"/>
                            </div>
                            <h4 className="title">{`Карта Apple`}</h4>
                            <p className='region'>{`Регион активации: ${item.country === "ru" ? "РФ" : item.country === "usa" ? "США" : ""}`}</p>

                            <p className="price">
                              <span className="original-price">
                             {`${item.price} р.`}
                              </span>
                                <span className="discount-price">
                             {`${(Math.ceil(increasedPrice / 10) * 10).toLocaleString()} р.`}
                                </span>
                            </p>


                            {cart.products.some((product) => product.id === item.id) ? (
                                <div className="catalog-add-line">
                                    <div
                                        onClick={() => {
                                            remove(item);
                                        }}
                                        className="catalog-add-btn"
                                    >
                                        -
                                    </div>
                                    <div
                                        className="counter">{cart.products.filter((each) => each.id === item.id).length}</div>
                                    <div onClick={() => add(item)} className="catalog-add-btn">
                                        +
                                    </div>
                                </div>
                            ) : (
                                <button className="default-btn" onClick={() => add(item)}>
                                    Купить
                                </button>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default Catalog;
