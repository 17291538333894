import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import axios from "axios";
import {websiteUrl} from '../Homepage/Homepage';

const Success = () => {
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const merchantOrderId = urlParams.get('MERCHANT_ORDER_ID');
        console.log(merchantOrderId);
        if (merchantOrderId) {
            axios.post(`${websiteUrl}/payment-success`, {
                MERCHANT_ORDER_ID: merchantOrderId
            }).then(response => {
                console.log('Запрос успешно отправлен:', response.data);
            }).catch(error => {
                console.error('Ошибка при отправке запроса:', error);
            });
        }
    }, [location]);

    return (
        <div className="container">
            <span className="success-icon">&#10003;</span>
            <h1>Оплата прошла успешно!</h1>
            <p>Ваш заказ успешно оплачен. Ваш код отправлен в чат бот <a href='https://t.me/kartaitunesru_bot'>@kartaitunesru_bot</a> </p>
        </div>
    );
};

export default Success;
