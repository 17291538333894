import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {addProductToCart, initUser} from '../../redux/reducers/user';
import {websiteUrl} from '../Homepage/Homepage';
import axios from 'axios';
import dotenv from 'dotenv';
import {useNavigate} from 'react-router-dom';

dotenv.config();

const Orders = () => {
    const dispatch = useDispatch();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (window.Telegram.WebApp.initData) {
            const authUser = JSON.parse(decodeUrl(window.Telegram.WebApp.initData).split('&')[1].split('=')[1]);
            const query_id = decodeUrl(window.Telegram.WebApp.initData).split('&')[0].split('=')[1];
            const hash = decodeUrl(window.Telegram.WebApp.initData).split('&')[3].split('=')[1];
            const auth_date = decodeUrl(window.Telegram.WebApp.initData).split('&')[2].split('=')[1];
            setUserData({...authUser, hash, auth_date, query_id});
        }
    }, []);

    const add = (product) => {
        dispatch(addProductToCart(product));
    };

    const navigate = useNavigate();

    const addAllToCart = (products) => {
        products.forEach(product => {
            add(product);
        });
        navigate('/cart');
    };

    useEffect(() => {
        if (userData) {
            axios.post(`${websiteUrl}/get-user-or-create`, userData).then((res) => {
                dispatch(initUser(res.data));
            });
        }
    }, [userData, dispatch]);

    let userId;
    if (userData) {
        userId = +userData.id;
    }

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (userId) {
            axios.get(`${websiteUrl}/get-user-orders/${userId}`)
                .then(res => {
                    setOrders(res.data);
                    setLoading(false);
                })
                .catch(err => {
                    setError(err);
                    setLoading(false);
                });
        }
    }, [userId]);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('Подарочная карта скопирована!');
            })
            .catch(err => {
                console.error('Ошибка копирования: ', err);
            });
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="cart">
            <h1 className="cart-title">Мои заказы</h1>
            {orders.length > 0 ? (
                orders.map(order => {
                    const formattedDate = new Date(order.date).toLocaleString('ru-RU', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                    });

                    return (
                        <div key={order._id} className="order-card">
                            <div className="order-header">
                                <h3 className="order-title">Заказ № {order._id}</h3>
                                <span className={`order-status ${order.status.toLowerCase()}`}>{order.status}</span>
                            </div>
                            <div className="order-info">
                                <p><strong>Дата:</strong> {formattedDate}</p>
                                <p><strong>Количество товаров:</strong> {order.products.length} </p>
                                <p><strong>Сумма:</strong> {order.total} руб.</p>
                            </div>
                            <button className="default-btn" onClick={() => addAllToCart(order.products)}>
                                Повторить заказ
                            </button>

                            <div className="products">
                                <h3>Товары:</h3>
                                <ul className="product-list">
                                    {order.products.map((product, index) => (
                                        <li key={product._id || index} className="product-item">
                                            <div className="product-content">
                                                <div className="product-details">
                                                    <p><strong>Номинал карты:</strong> {product.price} руб.</p>
                                                    <p><strong>Страна:</strong> {product.country}</p>

                                                    <strong>Подарочная карта:</strong>
                                                    <p style={{
                                                        cursor: 'pointer',
                                                        color: 'blue',
                                                        textDecoration: 'underline'
                                                    }}
                                                       onClick={() => copyToClipboard(product.key)}
                                                    >
                                                        {product.key}
                                                    </p>
                                                </div>
                                                <img src={product.photoSrc} alt={product.country}
                                                     className="product-image"/>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    );
                })
            ) : (
                <p className="no-orders">Нет заказов</p>
            )}
        </div>
    );
};

function decodeUrl(str) {
    return str
        .replace(/%7B/g, '{')
        .replace(/%22/g, '"')
        .replace(/%7D/g, '}')
        .replace(/%3A/g, ':')
        .replace(/%2C/g, ',')
        .replace(/%2F/g, '/')
        .replace(/%3F/g, '?')
        .replace(/%3D/g, '=')
        .replace(/%26/g, '&')
        .replace(/%2B/g, '+')
        .replace(/%25/g, '%');
}

export default Orders;
