import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import axios from "axios";
import {websiteUrl} from '../Homepage/Homepage';

const Failed = () => {
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const merchantOrderId = urlParams.get('MERCHANT_ORDER_ID');
        if (merchantOrderId) {
            axios.post(`${websiteUrl}/payment-failed`, {
                MERCHANT_ORDER_ID: merchantOrderId
            }).then(response => {
            }).catch(() => {
            });
        }
    }, [location]);

    return (
        <div className="error-container">
            <h1>Ошибка при оплате</h1>
            <p>К сожалению, оплата не удалась. Пожалуйста, свяжитесь с нашей технической поддержкой для получения
                помощи.</p>
            <a href="mailto:support@karta-itunes.ru" className="support-link">Связаться с техподдержкой</a>
        </div>
    );
};

export default Failed;
