import { createSlice } from '@reduxjs/toolkit';
import { websiteUrl } from '../../pages/Homepage/Homepage';
import axios from 'axios';

const userSlice = createSlice({
	name: 'user',
	initialState: {
		id: 0,
		username: '',
		cart: {
			products: [],
			total: 0,
		},
		orders: [],
	},
	reducers: {
		initUser: (state, { payload }) => {
			state.id = payload.id;
			state.username = payload.username;
			let total = 0;
			if (payload.cart?.products.length > 0) {
				state.cart.products = payload.cart.products;
				state.cart.products.map((productData) => (total += productData.price));
			}
			state.cart.total = total;
			state.orders = payload.orders;
		},

		addProductToCart: (state, { payload }) => {
			state.cart.products.push(payload);
			let total = 0;
			state.cart.products.map((productData) => (total += productData.price));
			state.cart.total = total;

			axios.post(websiteUrl + '/add-to-cart', { ...payload, userId: state.id });
		},
		removeProductFromCart: (state, { payload }) => {
			state.cart.total -= payload.price;
			const index = state.cart.products.findIndex((item) => item.id === payload.id);
			if (index !== -1) {
				state.cart.products.splice(index, 1);
			}

			axios.post(websiteUrl + '/remove-from-cart', { ...payload, userId: state.id });
		},

		removeProductFromCartMulti: (state, { payload }) => {
			console.log(payload);
			state.cart.products = state.cart.products.filter((item) => item.id !== payload.id);
			let total = 0;
			state.cart.products.map((productData) => (total += productData.price));
			state.cart.total = total;
			console.log(state.cart.products);
			axios.post(websiteUrl + '/remove-from-cart-multi', { ...payload, userId: state.id });
		},
	},
});

export const { initUser, addProductToCart, removeProductFromCart, removeProductFromCartMulti } = userSlice.actions;
export default userSlice.reducer;
