import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './redux/store';
import Homepage from './pages/Homepage/Homepage';

import './global.scss';
import Cartpage from './pages/Cart/Cartpage';
import Success from "./pages/Succces/Success";
import Failed from "./pages/Failed/Failed";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Homepage/>,
    },
    {
        path: '/cart',
        element: <Cartpage/>,
    },
    {
        path: '/success',
        element: <Success/>,
    },
    {
        path: '/failed',
        element: <Failed/>,
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <RouterProvider router={router}/>
    </Provider>,
);
